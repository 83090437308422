import { ChargerBoxesHeader } from "@/components/charger-boxes/charger-boxes-header";
import { PaymentBox } from "@/components/payment-box";
import { Button } from "@/components/ui/button";
import { Wrapper } from "@/components/wrapper";

import { Audio } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import { ChargerLocation } from "@/components/charger-location";
import ChargingSession from "@/components/charging-sesion";

import { cn } from "@/lib/utils/cn";

import { Info } from "lucide-react";

import { useCharging } from "@/lib/hooks/charging";
import { useEffect, useState } from "react";
import { resetMeterValues } from "@/redux/reducers/session/session.slice";
import ConnectorDropdown from "@/components/charging-connectors/connector-dropdown";

export default function StartCharger() {
  const {
    startCharging,
    stopCharging,
    isCharging,
    chargePointInfo,
    isReadyToCharge,
    startChargingInProgress,
    stopChargingInProgress,
  } = useCharging();
  const { wallet } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const [selectedConnectorId, setSelectedConnector] = useState(null);

  useEffect(() => {
    if (isCharging) {
      dispatch(resetMeterValues());
    }
  }, []);

  return (
    <Wrapper className="space-y-4">
      <div className=" p-4  rounded-2xl bg-[#CECECE30] flex flex-col items-center gap-1 w-full">
        <ChargerBoxesHeader status="free" />
      </div>

      <ChargerLocation
        lat={Number.parseFloat(chargePointInfo?.charge_point_latitude)}
        lng={Number.parseFloat(chargePointInfo?.charge_point_longitude)}
      />

      <PaymentBox />

      <div
        className={cn(
          Number.parseFloat(wallet?.balance) < 50.0 &&
            "blur-sm pointer-events-none select-none",
          "flex flex-col items-center gap-1"
        )}
      >
        <span className="flex gap-1 text-[10px] items-center text-[#666] text-center">
          <Info size={12} />
          <span>Charger meter values are updated at a 30 second interval</span>
        </span>
        <div className="flex flex-col gap-3 items-center w-full">
          <ChargingSession />
          <ConnectorDropdown
            connectors={chargePointInfo.charge_point_connectors}
            selectedConnector={selectedConnectorId}
            onSelectConnector={(connector) =>
              setSelectedConnector(connector.connector_id)
            }
          />

          {isCharging ? (
            <Button
              onClick={stopCharging}
              // disabled={stopCharingLoading || userLoading}
              disabled={!isCharging || stopChargingInProgress}
              className="bg-[#BC787869] tracking-[4px] border border-[#CECECE30]"
            >
              Stop Charging
            </Button>
          ) : (
            <Button
              onClick={() => startCharging(selectedConnectorId)}
              disabled={!isReadyToCharge || startChargingInProgress}
              // disabled={isCharging}
              className="bg-[#7EBC7869] tracking-[4px] border border-[#CECECE30]"
            >
              {startChargingInProgress ? "Starting..." : "Start Charging"}
              {isCharging ||
                (startChargingInProgress && (
                  <Audio
                    height="16"
                    width="16"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                  />
                ))}
            </Button>
          )}
        </div>
      </div>

      {/* <Lowbalance handleStopCharging={stopCharging} /> */}
    </Wrapper>
  );
}
