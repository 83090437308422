import { api } from "@/lib/utils/api";
import { baseQueryWithoutAuth } from "@/lib/utils/redux-base-query";
import { setChargePointInfo } from "./charger.slice";

export const chargerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChargerDetail: builder.query({
      query: (chargePointId) => {
        return `/api/v1/drive/chargers/charge-point-info/?charge_point_id=${chargePointId}`;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setChargePointInfo(data.data));
        } catch (error) {
          console.error("error", error);
        }
      },
    }),
    getChargersWithinDistance: builder.query({
      query: (values) => {
        return `/api/v1/drive/chargers/nearby-charge-points/?latitude=${values.latitude}&longitude=${values.longitude}&distance=${values.distance}`;
      },
    }),
    remoteStartTransaction: builder.mutation({
      query: (values) => ({
        url: "/api/v1/core_central_ocpp/remote_start_transaction/",
        method: "POST",
        body: values,
      }),
    }),
    searchChargers: builder.query({
      queryFn: async (args, api, extraOptions) => {
        const params = new URLSearchParams();
        const { searchTerm, longitude, latitude, max_distance, page } = args;

        if (searchTerm) params.append("searchTerm", searchTerm);
        if (latitude) params.append("latitude", latitude);
        if (longitude) params.append("longitude", longitude);
        if (max_distance) params.append("max_distance", max_distance);
        if (page) params.append("page", page);

        const queryUrl = `api/v1/core_backend/charge-point-search/?${params.toString()}`;
        return baseQueryWithoutAuth(queryUrl, api, extraOptions);
      },
    }),
    remoteStopTransaction: builder.mutation({
      query: (values) => ({
        url: "/api/v1/core_central_ocpp/remote_stop_transaction/",
        method: "POST",
        body: values,
      }),
    }),
    getPastChargingSessions: builder.query({
      query: ({ page }) =>
        `/api/v1/drive/chargers/charging-sessions/?page=${page}`,

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      merge: (currentCache, newItems) => {
        if (!newItems.previous) {
          currentCache.results = newItems.results;
          currentCache.next = newItems.next;
          currentCache.previous = newItems.previous;
          return;
        }
        currentCache.results = [...currentCache.results, ...newItems.results];
        currentCache.next = newItems.next;
        currentCache.previous = newItems.previous;
      },

      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useGetChargerDetailQuery,
  useLazyGetChargerDetailQuery,
  useRemoteStartTransactionMutation,
  useRemoteStopTransactionMutation,
  useGetPastChargingSessionsQuery,
  useGetChargersWithinDistanceQuery,
  useLazyGetChargersWithinDistanceQuery,
  useSearchChargersQuery,
  useLazySearchChargersQuery,
} = chargerApi;
