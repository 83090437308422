import { Skeleton } from "@/components/ui/skeleton";
import { useGetWalletHistoryQuery } from "@/redux/reducers/wallet/wallet.api";
import { useState } from "react";
import { Button } from "../ui/button";
import WalletHistoryItem from "./wallet-history-item";

const WalletHistoryHeader = () => (
  <h2 className="text-xl font-light text-left pb-5">Wallet History</h2>
);

const WalletHistorySkeleton = () => (
  <div className="space-y-4">
    <Skeleton className="rounded-lg h-[45px]" />
    <Skeleton className="rounded-lg h-[45px]" />
    <Skeleton className="rounded-lg h-[45px]" />
  </div>
);

const WalletHistoryError = () => (
  <div className="text-center p-4 bg-[#cecece09] rounded-2xl text-[#FFFFFFCC]">
    Error
  </div>
);

const NoTransactionsMessage = () => (
  <div className="text-center p-4 bg-[#cecece09] rounded-2xl text-[#FFFFFFCC]">
    No Transactions Yet
  </div>
);

const WalletHistoryList = ({ results }) => (
  <div className="space-y-4 max-h-[300px] overflow-y-auto">
    {results.map((item) => (
      <WalletHistoryItem
        key={item.id}
        transactionTime={item.transaction_time}
        amount={item.amount}
        status={item.status}
        transactionType={item.transaction_type}
      />
    ))}
  </div>
);

const LoadMoreButton = ({ isLoading, onClick }) => (
  <div className="text-center">
    <Button disabled={isLoading} onClick={onClick}>
      Load More
    </Button>
  </div>
);

const DepositList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: walletHistoryData,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useGetWalletHistoryQuery({ page: currentPage });

  const handleLoadMore = () => setCurrentPage((prevPage) => prevPage + 1);

  return (
    <div>
      <WalletHistoryHeader />
      {isLoading && <WalletHistorySkeleton />}
      {isError && <WalletHistoryError />}
      {isSuccess &&
        walletHistoryData &&
        (walletHistoryData.results.length ? (
          <>
            <WalletHistoryList results={walletHistoryData.results} />
            {walletHistoryData.next && (
              <LoadMoreButton
                isLoading={isLoading || isFetching}
                onClick={handleLoadMore}
              />
            )}
          </>
        ) : (
          <NoTransactionsMessage />
        ))}
      {!isLoading && !isError && !isSuccess && <NoTransactionsMessage />}
    </div>
  );
};

export default DepositList;
