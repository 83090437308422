import { DeleteAccount } from "@/components/manage-account/delete-account.modal";
import { EditAccount } from "@/components/manage-account/edit-account.modal";
import { PaymentBox } from "@/components/payment-box";
import { NewUser } from "@/components/welcome-modals/new-user";
import { ReturningUser } from "@/components/welcome-modals/returning-user";
import { ReturningUserIncomplete } from "@/components/welcome-modals/returning-user-incomplete";
import { Wrapper } from "@/components/wrapper";
import { useGetUserProfileQuery } from "@/redux/reducers/auth/auth.api";
import { setHasCheckedProfile } from "@/redux/reducers/auth/auth.slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Account() {
  const dispatch = useDispatch();
  const { hasCheckedProfile } = useSelector((state) => state.auth);

  const { data: userProfileData } = useGetUserProfileQuery();

  const [isNew, setIsNew] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturningIncomplete, setIsReturningIncomplete] = useState(false);

  useEffect(() => {
    if (userProfileData && !hasCheckedProfile) {
      const { email, first_name, last_name } = userProfileData.data;
      dispatch(setHasCheckedProfile(true));
      setIsReturning(true);
      if (email && first_name && last_name) {
        const timeout = setTimeout(() => {
          setIsReturning(false);
        }, 1500);
        return () => clearTimeout(timeout);
      }
      if (email || first_name || last_name) {
        setIsReturning(false);
        setIsReturningIncomplete(true);
      } else {
        setIsReturning(false);
        setIsNew(true);
      }
    }
  }, [userProfileData, hasCheckedProfile, dispatch]);

  return (
    <Wrapper>
      <div className="h-[70vh] justify-between items-center flex w-full flex-col">
        <div className="space-y-5">
          <h1 className="capitalize tracking-[4.5px] text-xl">
            Account Management
          </h1>

          <div className="space-y-3 flex flex-col  items-center w-full mx-auto">
            <PaymentBox />
            <EditAccount />
            <DeleteAccount />
          </div>
        </div>
        <ReturningUser
          isOpen={isReturning}
          onClose={() => setIsReturning(false)}
        />
        <ReturningUserIncomplete
          isOpen={isReturningIncomplete}
          onClose={() => setIsReturningIncomplete(false)}
        />
        <NewUser isOpen={isNew} onClose={() => setIsNew(false)} />
      </div>
    </Wrapper>
  );
}
