import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";

export const ReturningUser = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <Dialog open={isOpen} modal>
      <DialogContent onClose={onClose} className="max-w-xs border-[#333]">
        <div className="flex items-center flex-col gap-4">
          <div>
            <img src="/images/account.png" alt="" height={100} width={100} />
          </div>
          <DialogTitle className="text-center text-3xl font-semibold">
            Welcome back
          </DialogTitle>
          <p className="text-center text-sm text-[#666]">
            Welcome back to GridFlow drive app.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};
