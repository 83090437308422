import { Button } from "@/components/ui/button";
import { Wrapper } from "@/components/wrapper";
import app from "@/lib/config/firebase.config";
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import { ArrowRight } from "lucide-react";
import { PhoneInput } from "react-international-phone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import "react-international-phone/style.css";
import { AccountBox } from "@/components/charger-boxes/account-box";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { VerifyAuth } from "@/components/verify-auth";
import { cn } from "@/lib/utils/cn";
import {
  extractCountryCode,
  extractCurrencySymbol,
} from "@/lib/utils/currency";
import {
  setAuthLoading,
  setIsAuthenticated,
  setPhoneNumber,
  setSigninConfirmation,
  setUser,
} from "@/redux/reducers/auth/auth.slice";
import { setCurrency } from "@/redux/reducers/wallet/wallet.slice";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  phone_number: z
    .string({ required_error: "Phone Number is required" })
    .refine((val) => /\+\d{11,15}/.test(val), {
      message:
        "Invalid phone number format. Phone number should start with a '+' and be followed by 11 to 15 digits.",
    }),
});

export default function AccountAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [verified, setVerified] = useState(false);

  const { signinConfirmation, userPhoneNumber } = useSelector(
    (state) => state.auth,
  );

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const auth = getAuth(app);

  if (import.meta.env.VITE_ENV !== "production") {
    auth.settings.appVerificationDisabledForTesting = true;
  }

  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-confirm",
        {
          size: "normal",
          callback: () => {
            // reCAPTCHA solvred - allow signInWithPhoneNumber.
            handleSubmit();
          },
          "expired-callback": (error) => {
            console.error("error: ", error);
            console.error("expired-callback");
          },
        },
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Error during RecaptchaVerifier render:", error);
      });
    } catch (e) {
      console.error("Error during RecaptchaVerifier:", e);
    }

    return () => {
      window.recaptchaVerifier.clear();
    };
  }, [auth]);

  const handleSubmit = (data) => {
    dispatch(setPhoneNumber(data.phone_number));

    signInWithPhoneNumber(auth, data.phone_number, window.recaptchaVerifier)
      .then((response) => {
        dispatch(setSigninConfirmation(response));
        toast.success("Check your phone for OTP");
        setVerified(true);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const resendOTP = () => {
    handleSubmit({ phone_number: userPhoneNumber });
  };

  function onSubmit(data) {
    dispatch(setAuthLoading(true));
    signinConfirmation
      .confirm(data.pin)
      .then((response) => {
        dispatch(setUser(response.user));
        sessionStorage.setItem(
          "access::token",
          response._tokenResponse.idToken,
        );
        sessionStorage.setItem(
          "refresh::token",
          response._tokenResponse.refreshToken,
        );
        toast.success("Successfully signed in!");
        dispatch(setIsAuthenticated(true));
        const countryCode = extractCountryCode(response.user.phoneNumber);
        if (countryCode) {
          const currencySymbol = extractCurrencySymbol(countryCode);
          dispatch(setCurrency(currencySymbol));
        } else {
          dispatch(setCurrency("$"));
        }
        navigate("/account");
      })
      .catch((error) => toast.error(error.message))
      .finally(() => dispatch(setAuthLoading(false)));
  }

  return (
    <Wrapper>
      <AccountBox />
      {verified ? (
        <VerifyAuth onSubmit={onSubmit} resendOTP={resendOTP} />
      ) : (
        <div className="flex flex-col items-center gap-4">
          <span>Enter your phone number to get started</span>
          <div className="  flex flex-col items-center gap-4">
            <hr className=" border-[#dedede93] w-full" />
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleSubmit)}
                className="flex gap-[2px]"
              >
                <FormField
                  name="phone_number"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem
                      style={{
                        borderRadius: "8px 0px 0px 8px",
                        background: "rgba(255, 255, 255, 0.45)",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      }}
                      className="relative rounded-l-lg  flex-[5] h-full"
                    >
                      <FormControl>
                        <PhoneInput
                          forceDialCode
                          defaultCountry="hk"
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          onBlur={field.onBlur}
                          className="flex  rounded-l-lg "
                          style={{
                            boxShadow: " 0px 0px 20px 0px #FED127 inset",
                          }}
                          inputClassName={cn(
                            "flex  w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
                            "!border-0 !bg-transparent",
                          )}
                          countrySelectorStyleProps={{
                            buttonClassName:
                              "w-[50px] pr-0 !border-0 !bg-transparent",
                          }}
                          disableDialCodePrefill
                        />
                      </FormControl>

                      <FormMessage className=" text-[10px] absolute" />
                    </FormItem>
                  )}
                />
                <div
                  className="  rounded-r-lg flex-[1] "
                  style={{
                    background: "rgba(255, 255, 255, 0.45)",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  }}
                >
                  <Button
                    type="submit"
                    id="sign-in-button"
                    className=" bg-transparent w-full h-full border-0  rounded-r-lg rounded-l-none"
                    style={{
                      boxShadow: "  0px 0px 20px 0px #FED127 inset",
                    }}
                  >
                    <ArrowRight size={20} />
                  </Button>
                </div>
              </form>
              <div id="recaptcha-confirm" />
            </Form>
            <span className=" text-gray-500 text-sm whitespace-nowrap">
              You will receive a verification code.
            </span>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
