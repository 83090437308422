import { Skeleton } from "@/components/ui/skeleton";
import { PastSessionBox } from "@/components/wallet/past-session-box";
import { useGetPastChargingSessionsQuery } from "@/redux/reducers/charger/charger.api";
import { useState } from "react";
import { Button } from "../ui/button";

const LoadingState = () => (
  <div className="space-y-4">
    <Skeleton className="rounded-lg h-[45px]" />
    <Skeleton className="rounded-lg h-[45px]" />
    <Skeleton className="rounded-lg h-[45px]" />
  </div>
);

const ErrorState = () => <>Error with getting charging sessions</>;

const NoTransactionsState = () => (
  <div className="text-center p-4 bg-[#cecece09] rounded-2xl text-[#FFFFFFCC]">
    No Transactions Yet
  </div>
);

const SessionListContent = ({
  sessions,
  next,
  onLoadMore,
  isLoading,
  isFetching,
}) => (
  <div className="space-y-4 max-h-[300px] overflow-y-auto">
    {sessions.length > 0 ? (
      <>
        {sessions.map((item) => (
          <PastSessionBox
            key={item?.id}
            address={item.charger_location}
            energyConsumed={item.energy_consumed}
            cost={item.amount}
            date={item.end_time}
          />
        ))}
        {next && (
          <div className="text-center">
            <Button onClick={onLoadMore} disabled={isLoading || isFetching}>
              Load More
            </Button>
          </div>
        )}
      </>
    ) : (
      <NoTransactionsState />
    )}
  </div>
);

const SessionsList = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: chargingSessionsData,
    isLoading: chargingSessionsIsLoading,
    isError: chargingSessionsIsError,
    isSuccess: chargingSessionsIsSuccess,
    isFetching: chargingSessionsIsFetching,
  } = useGetPastChargingSessionsQuery({
    page: currentPage,
  });

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div>
      <h2 className="text-xl font-light text-left pb-5">Past Sessions</h2>
      {chargingSessionsIsLoading && <LoadingState />}
      {chargingSessionsIsError && <ErrorState />}
      {chargingSessionsIsSuccess && (
        <SessionListContent
          sessions={chargingSessionsData.results || []}
          next={chargingSessionsData.next}
          onLoadMore={handleLoadMore}
          isLoading={chargingSessionsIsLoading}
          isFetching={chargingSessionsIsFetching}
        />
      )}
    </div>
  );
};

export default SessionsList;
