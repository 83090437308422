import { ArrowLeftCircle } from "lucide-react";
import { Link } from "react-router-dom";

const BackButton = () => {
  return (
    <Link to="/account" className="absolute top-2 left-0">
      <ArrowLeftCircle />
    </Link>
  );
};

export default BackButton;
