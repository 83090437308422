import { Navbar } from "@/components/nav";
import { AuthRoutes } from "@/layout/auth-routes";
import { ChargeOnlyRoutes } from "@/layout/charge-only-routes";
import { PrivateRoutes } from "@/layout/private-routes";
import Account from "@/pages/account";
import AccountAuth from "@/pages/account-auth";
import AccountAuthVerify from "@/pages/account-auth-verify";
import AccountWallet from "@/pages/account-wallet";
import Auth from "@/pages/auth";
import AuthVerify from "@/pages/auth-verify";
import AvailableChargers from "@/pages/available-chargers";
import Busy from "@/pages/busy";
import Chargers from "@/pages/chargers";
import CompleteCharge from "@/pages/complete-charge";
import ErrorPage from "@/pages/error-404";
import Home from "@/pages/home";
import PluginCharger from "@/pages/plugin-charger";
import StartCharger from "@/pages/start-charger";
import WalletTopUp from "@/pages/wallet-topup";

const privateRoutes = [
  {
    path: "/account",
    element: Account,
  },
  {
    path: "/account/wallet",
    element: AccountWallet,
  },
  {
    path: "/account/wallet/success",
    element: WalletTopUp,
  },
];

export const chargeOnlyRoutes = [
  {
    path: "/plugin-charger",
    element: PluginCharger,
  },
  {
    path: "/start-charger",
    element: StartCharger,
  },
  {
    path: "/complete-charge",
    element: CompleteCharge,
  },
];

const authRoutes = [
  {
    path: "/auth",
    element: Auth,
  },
  {
    path: "/auth/verify",
    element: AuthVerify,
  },
  {
    path: "/account/auth",
    element: AccountAuth,
  },
  {
    path: "/account/auth/verify",
    element: AccountAuthVerify,
  },
];
const publicRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/busy",
    element: (
      <>
        <Navbar />
        <Busy />
      </>
    ),
  },
  {
    path: "/chargers",
    element: (
      <>
        <Navbar />
        <Chargers />
      </>
    ),
  },
  {
    path: "/available-chargers",
    element: (
      <>
        <Navbar />
        <AvailableChargers />
      </>
    ),
  },
  { path: "*", element: <ErrorPage /> },
];

const routes = [
  ...publicRoutes,
  ...authRoutes.map((route) => ({
    path: route.path,
    element: (
      <AuthRoutes>
        <route.element />
      </AuthRoutes>
    ),
  })),
  ...chargeOnlyRoutes.map((route) => ({
    path: route.path,
    element: (
      <ChargeOnlyRoutes>
        <Navbar />
        <route.element />
      </ChargeOnlyRoutes>
    ),
  })),
  ...privateRoutes.map((route) => ({
    path: route.path,
    element: (
      <PrivateRoutes>
        <Navbar />
        <route.element />
      </PrivateRoutes>
    ),
  })),
];

export default routes;
