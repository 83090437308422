import { Skeleton } from "@/components/ui/skeleton";
import { useGetWalletQuery } from "@/redux/reducers/wallet/wallet.api";
import { useSelector } from "react-redux";

export const WalletCard = () => {
  const { currency } = useSelector((state) => state.wallet);

  const {
    data: walletData,
    isLoading: walletLoading,
    isError: isWalletError,
    isSuccess: isWalletSuccess,
  } = useGetWalletQuery();

  if (walletLoading) {
    return <Skeleton className="rounded-lg h-[200px]" />;
  }

  if (isWalletError) {
    return (
      <div className="rounded-2xl p-3 h-[200px] flex items-center justify-center bg-[#f8d7da] text-[#721c24]">
        Error getting Balance, please retry
      </div>
    );
  }

  return (
    isWalletSuccess && (
      <div
        style={{
          background:
            "linear-gradient(120deg, rgba(254, 209, 39, 0.00) 7.79%, #FFCA00 208.75%), rgba(206, 206, 206, 0.18)",
          backdropFilter: "blur(10px)",
        }}
        className="rounded-2xl p-3 overflow-hidden h-[200px] relative"
      >
        <div
          style={{
            background: "url(/images/logo-payment.png)",
          }}
          className="h-[208px] w-[227px] !bg-contain absolute !bg-center -left-20 top-16 !opacity-20"
        />
        <div className="flex flex-col justify-between h-full items-end">
          <div className="text-[#FFFFFF47] text-2xl font-semibold">
            Drive Wallet
          </div>
          <div className="text-2xl font-semibold">
            {currency || ""}
            {Number.parseFloat(walletData?.data?.balance || 0).toLocaleString()}
          </div>
        </div>
      </div>
    )
  );
};
