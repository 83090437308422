import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LightButton } from "@/components/ui/light-button";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import "react-international-phone/style.css";
import { Skeleton } from "@/components/ui/skeleton";
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from "@/redux/reducers/auth/auth.api";
import { Edit } from "iconsax-react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";

const formSchema = z.object({
  first_name: z.string({ required_error: "First Name is required" }).optional(),
  last_name: z.string({ required_error: "Last Name is required" }).optional(),
  email: z
    .string()
    .email({ required_error: "Email is required" })
    .max(100, { message: "Email address is too long" })
    .optional(),
});

export const EditAccount = () => {
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const [updateAccount, { isLoading }] = useUpdateUserProfileMutation();
  const { data: userProfileData, isLoading: userProfileLoading } =
    useGetUserProfileQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("edit-account") === "true") {
      setIsOpen(true);
    }
  }, [searchParams]);

  const handleSubmit = (data) => {
    const { first_name, last_name, email } = data;

    if (!first_name && !last_name && !email) {
      toast.error("Please fill in any field to edit.");
      return;
    }
    updateAccount(data)
      .unwrap()
      .then((response) => {
        form.reset();
        toast.success(response.message);
        setIsOpen(false);
        setSearchParams({});
      })
      .catch((error) => {
        toast.error(error.data.error_message);
      });
  };

  return (
    <Dialog modal open={isOpen}>
      <DialogTrigger
        onClick={() => {
          setIsOpen(true);
          form.reset();
          setSearchParams({ "edit-account": "true" });
        }}
        asChild
      >
        <LightButton
          icon={Edit}
          className="w-full justify-between !p-3 h-auto rounded-2xl"
        >
          Edit your account
        </LightButton>
      </DialogTrigger>
      <DialogContent
        onClose={() => {
          setIsOpen(false);
          form.reset();
          setSearchParams({});
        }}
        className=" max-w-xs space-y-6 items-center border-none rounded-[30px] backdrop-blur-[10px] bg-[#CECECE2E] "
      >
        <DialogHeader>
          <DialogTitle className=" text-[28px] font-bold">
            Edit your Account
          </DialogTitle>
          <DialogDescription className="text-white">
            This is a irreversible action, once you delete your account it
            cannot be recovered
          </DialogDescription>
        </DialogHeader>
        {userProfileLoading ? (
          <div className="space-y-6">
            <div className="flex gap-2">
              <Skeleton className="rounded-lg h-[32px] w-full" />
              <Skeleton className="rounded-lg h-[32px] w-full" />
            </div>
            <hr className="w-full border-{#6c6b6b] " />
            <Skeleton className="rounded-lg h-10" />
          </div>
        ) : (
          userProfileData && (
            <div className="space-y-6 flex-col flex items-center">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(handleSubmit)}
                  className="space-y-6 w-full"
                >
                  <div className="space-y-4">
                    <div className="flex gap-2">
                      <FormField
                        name="first_name"
                        control={form.control}
                        render={({ field }) => (
                          <FormItem className="relative rounded-l-lg !rounded-r-none  flex-[5] h-full">
                            <FormControl>
                              <Input
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px 0px #fecf2775 inset",
                                }}
                                className="border-0 bg-transparent"
                                placeholder={
                                  userProfileData.data.first_name
                                    ? userProfileData.data.first_name
                                    : "First Name"
                                }
                                {...field}
                              />
                            </FormControl>
                            <FormMessage className=" text-[10px] absolute" />
                          </FormItem>
                        )}
                      />
                      <FormField
                        name="last_name"
                        control={form.control}
                        render={({ field }) => (
                          <FormItem className="relative rounded-l-lg !rounded-r-none  flex-[5] h-full">
                            <FormControl>
                              <Input
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px 0px #fecf2775 inset",
                                }}
                                className="border-0 bg-transparent"
                                placeholder={
                                  userProfileData.data.last_name
                                    ? userProfileData.data.last_name
                                    : "Last Name"
                                }
                                {...field}
                              />
                            </FormControl>
                            <FormMessage className=" text-[10px] absolute" />
                          </FormItem>
                        )}
                      />
                    </div>

                    <FormField
                      name="email"
                      control={form.control}
                      render={({ field }) => (
                        <FormItem className="relative rounded-l-lg !rounded-r-none  flex-[5] h-full">
                          <FormControl>
                            <Input
                              type="email"
                              style={{
                                boxShadow: " 0px 0px 20px 0px #fecf2775 inset",
                              }}
                              className="border-0 bg-transparent"
                              placeholder={
                                userProfileData.data.email
                                  ? userProfileData.data.email
                                  : "Email"
                              }
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className=" text-[10px] absolute" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <hr className="w-full border-{#6c6b6b] " />
                  <LightButton
                    icon={ArrowRight}
                    iconSide="right"
                    type="submit"
                    id="sign-in-button"
                    className=" bg-black w-full  border-0 !h-10 "
                    disabled={isLoading}
                  >
                    Update Account
                  </LightButton>
                  <div id="recaptcha" />
                </form>
              </Form>
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};
