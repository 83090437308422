import { AccountBox } from "@/components/charger-boxes/account-box";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { Wrapper } from "@/components/wrapper";
import app from "@/lib/config/firebase.config";
import {
  extractCountryCode,
  extractCurrencySymbol,
} from "@/lib/utils/currency";
import {
  setAuthLoading,
  setIsAuthenticated,
  setSigninConfirmation,
  setUser,
} from "@/redux/reducers/auth/auth.slice";
import { setCurrency } from "@/redux/reducers/wallet/wallet.slice";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const FormSchema = z.object({
  pin: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
});

export default function AccountAuthVerify() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth(app);
  const { signinConfirmation, loading, userPhoneNumber, verifier } =
    useSelector((state) => state.auth);

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: "",
    },
  });

  const [countdown, setCountdown] = useState(30);

  const handleResendOTP = () => {
    dispatch(setAuthLoading(true));
    signInWithPhoneNumber(auth, userPhoneNumber, verifier)
      .then((response) => {
        dispatch(setSigninConfirmation(response));
        toast.success("Check your phone for OTP");
        navigate("/account/auth/verify");
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => dispatch(setAuthLoading(false)));
  };

  function onSubmit(data) {
    dispatch(setAuthLoading(false));
    signinConfirmation
      .confirm(data.pin)
      .then((response) => {
        dispatch(setUser(response.user));

        sessionStorage.setItem(
          "access::token",
          response._tokenResponse.idToken,
        );
        toast.success("Successfully signed in!");
        dispatch(setIsAuthenticated(true));
        const countryCode = extractCountryCode(response.user.phoneNumber);
        if (countryCode) {
          const currencySymbol = extractCurrencySymbol(countryCode);
          dispatch(setCurrency(currencySymbol));
        } else {
          dispatch(setCurrency("$"));
        }
        navigate("/account");
      })
      .catch((error) => toast.error(error.message))
      .finally(() => dispatch(setAuthLoading(false)));
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <Wrapper>
      <AccountBox />

      <div className="flex flex-col items-center gap-4">
        <span>Please enter the verification code </span>
        <div className="w-[90%]  flex flex-col items-center gap-4">
          <hr className=" border-[#dedede93] w-full" />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
              <FormField
                control={form.control}
                name="pin"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="flex gap-[2px]">
                        <InputOTP
                          onComplete={form.handleSubmit(onSubmit)}
                          className=""
                          maxLength={6}
                          disabled={loading}
                          {...field}
                        >
                          <InputOTPGroup className="rounded-none">
                            <InputOTPSlot
                              style={{
                                background: "rgba(255, 255, 255, 0.45)",
                                boxShadow:
                                  "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127, inset 20px 0px 20px -20px #FED127",
                              }}
                              className="text-sm border-none"
                              index={0}
                            />
                            <InputOTPSlot
                              style={{
                                background: "rgba(255, 255, 255, 0.45)",
                                boxShadow:
                                  "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                              }}
                              className="text-sm border-none"
                              index={1}
                            />
                            <InputOTPSlot
                              style={{
                                background: "rgba(255, 255, 255, 0.45)",
                                boxShadow:
                                  "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                              }}
                              className="text-sm border-none"
                              index={2}
                            />
                            <InputOTPSlot
                              style={{
                                background: "rgba(255, 255, 255, 0.45)",
                                boxShadow:
                                  "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                              }}
                              className="text-sm border-none"
                              index={3}
                            />
                            <InputOTPSlot
                              style={{
                                background: "rgba(255, 255, 255, 0.45)",
                                boxShadow:
                                  "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                              }}
                              className="text-sm border-none"
                              index={4}
                            />
                            <InputOTPSlot
                              style={{
                                background: "rgba(255, 255, 255, 0.45)",
                                boxShadow:
                                  "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127, inset -20px 0px 20px -20px #FED127",
                              }}
                              className="text-sm last:rounded-r-none border-none"
                              index={5}
                            />
                          </InputOTPGroup>
                        </InputOTP>
                        <div
                          className="  rounded-r-lg flex-[1]"
                          style={{
                            background: "rgba(255, 255, 255, 0.45)",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          }}
                        >
                          <Button
                            disabled={loading}
                            type="submit"
                            className=" bg-transparent w-full h-full border-0 rounded-l-none"
                            style={{
                              boxShadow: "  0px 0px 20px 0px #FED127 inset",
                            }}
                          >
                            <ArrowRight />
                          </Button>
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <span className=" text-gray-500 text-sm whitespace-nowrap">
            Didn’t receive the code?{" "}
            {countdown === 0 ? (
              <Button
                onClick={handleResendOTP}
                variant="link"
                className="font-bold text-white p-0 "
              >
                Resend ({countdown})
              </Button>
            ) : (
              ` Resend (${countdown})`
            )}
          </span>
        </div>
      </div>
    </Wrapper>
  );
}
