import { formatDateString } from "@/lib/utils/date";
import { useSelector } from "react-redux";

const WalletHistoryItem = ({ transactionTime, amount, transactionType }) => {
  const { currency } = useSelector((state) => state.wallet);

  // Normalize transaction type
  const normalizedType = transactionType?.toLowerCase();

  // Determine styling and prefix
  let transactionStyles = "";
  if (normalizedType === "debit") {
    transactionStyles = "text-red-400";
  } else if (normalizedType === "credit") {
    transactionStyles = "text-green-400";
  }

  let prefix = "";
  if (normalizedType === "debit") {
    prefix = "-";
  } else if (normalizedType === "credit") {
    prefix = "+";
  }

  return (
    <div className="w-full px-5 py-3 bg-[#CECECE2E] rounded-2xl flex items-center justify-between">
      <p>{formatDateString(transactionTime)}</p>
      <p className={transactionStyles}>
        {prefix} {currency}
        {amount}
      </p>
    </div>
  );
};
export default WalletHistoryItem;
