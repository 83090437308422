import BackButton from "@/components/back-button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AddFunds } from "@/components/wallet/add-funds";
import DepositList from "@/components/wallet/deposit-list";
import { Funding } from "@/components/wallet/funding";
import SessionsList from "@/components/wallet/sessions-list";
import { WalletCard } from "@/components/wallet/wallet-card";
import { Wrapper } from "@/components/wrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AccountWallet() {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const [isFunding, setIsFunding] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("fund-account") === "true") {
      setIsFunding(true);
    } else {
      setIsFunding(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <div className="w-full">
      <Wrapper className="w-full">
        <BackButton />
        <div className=" space-y-4">
          <WalletCard />

          {isFunding ? (
            <Funding
              onClose={() => {
                setIsFunding(false);
                setSearchParams({});
              }}
            />
          ) : (
            <>
              <AddFunds
                onClick={() => {
                  setIsFunding(true);
                  setSearchParams({ "fund-account": "true" });
                }}
              />

              <Tabs
                defaultValue="transactions"
                className="bg-transparent container space-y-6 p-0 "
              >
                <TabsList className="flex h-14 bg-slate-700">
                  <TabsTrigger
                    value="transactions"
                    className="flex-1 w-full h-full font-bold"
                  >
                    Past Sessions
                  </TabsTrigger>
                  <TabsTrigger
                    value="deposits"
                    className="flex-1 w-full h-full font-bold"
                  >
                    Wallet History
                  </TabsTrigger>
                </TabsList>
                <TabsContent
                  className="data-[state=inactive]:hidden"
                  forceMount
                  value="transactions"
                >
                  <SessionsList />
                </TabsContent>
                <TabsContent
                  className="data-[state=inactive]:hidden"
                  forceMount
                  value="deposits"
                >
                  <DepositList />
                </TabsContent>
              </Tabs>
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
}
