"use client";

import { cn } from "@/lib/utils/cn";
import { motion } from "framer-motion";
import { MenuIcon, XIcon } from "lucide-react";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const CONTAINER_SIZE = 170;

const FamilyButton = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef(null);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleClickOutside = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsExpanded(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setIsExpanded(false);
  }, []);

  return (
    <FamilyButtonContainer
      ref={ref}
      isExpanded={isExpanded}
      toggleExpand={toggleExpand}
    >
      {isExpanded ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.4,
              ease: "easeOut",
            },
          }}
        >
          {children}
        </motion.div>
      ) : null}
    </FamilyButtonContainer>
  );
};

// A container that wraps content and handles animations

const FamilyButtonContainer = React.forwardRef(
  ({ isExpanded, toggleExpand, children }, ref) => {
    const location = useLocation();
    return (
      <motion.div
        ref={ref}
        className={cn(
          "relative    shadow-lg flex flex-col space-y-1  items-center  text-white  cursor-pointer z-10",
          !isExpanded
            ? "bg-gradient-to-b from-neutral-900 to-stone-900 dark:from-stone-700 dark:to-neutral-800/80"
            : "",
        )}
        layoutRoot
        layout
        initial={{ borderRadius: 21, width: "4rem", height: "4rem" }}
        animate={
          isExpanded
            ? {
                borderRadius: 20,
                width: CONTAINER_SIZE - 70,
                height:
                  location.pathname !== "/account/wallet" ||
                  location.pathname !== "/account"
                    ? CONTAINER_SIZE + 130
                    : CONTAINER_SIZE + 70,

                transition: {
                  type: "spring",
                  damping: 25,
                  stiffness: 400,
                  when: "beforeChildren",
                },
              }
            : {
                borderRadius: 21,
                width: "4rem",
                height: "4rem",
              }
        }
      >
        {children}

        <motion.div
          className="absolute  "
          initial={{ x: "-50%" }}
          animate={{
            x: isExpanded ? "0%" : "-50%",
            transition: {
              type: "tween",
              ease: "easeOut",
              duration: 0.3,
            },
          }}
          style={{
            left: isExpanded ? "" : "50%",
            bottom: 6,
          }}
        >
          {isExpanded ? (
            <motion.div
              className="p-[10px] group bg-neutral-800/50 dark:bg-black/50 border border-cyan-100/30 hover:border-neutral-200 text-orange-50 rounded-full shadow-2xl transition-colors duration-300 "
              onClick={toggleExpand}
              layoutId="expand-toggle"
              initial={false}
              animate={{
                rotate: -360,
                transition: {
                  duration: 0.4,
                },
              }}
            >
              <XIcon
                className={cn(
                  "h-7 w-7 text-cyan-100/30 dark:text-neutral-400/80 group-hover:text-neutral-500 transition-colors duration-200 ",
                )}
              />
            </motion.div>
          ) : (
            <motion.div
              className={cn(
                "p-[10px] group bg-neutral-200 dark:bg-cyan-500/90 text-cyan-50 border border-cyan-100/10  shadow-2xl transition-colors duration-200",
              )}
              style={{ borderRadius: 24 }}
              onClick={toggleExpand}
              layoutId="expand-toggle"
              initial={{ rotate: 180 }}
              animate={{
                rotate: -180,
                transition: {
                  duration: 0.4,
                },
              }}
            >
              <MenuIcon className="h-7 w-7 text-black dark:text-neutral-900" />
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    );
  },
);

export { FamilyButton };
