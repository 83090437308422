import { Wallet } from "lucide-react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const WalletButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);

  if (location.pathname === "/account/wallet" || !user) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={() => navigate("/wallet")}
      className="bg-green-800 hover:bg-green-900 disabled:bg-gray-500 p-5 rounded-full"
    >
      <Wallet />
    </button>
  );
};

export default WalletButton;
