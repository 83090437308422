"use client";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Slider } from "@/components/ui/slider";
import { Wrapper } from "@/components/wrapper";
import { cn } from "@/lib/utils/cn";
import {
  useLazySearchChargersQuery,
  useSearchChargersQuery,
} from "@/redux/reducers/charger/charger.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowUp } from "iconsax-react";
import { BatteryCharging, ChevronDown, Info, Plug } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useGoogleMapsScript } from "use-google-maps-script";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import * as z from "zod";

const formSchema = z.object({
  // searchTerm: z.string({required_error:""}).optional(),
  address: z.string({ required_error: "Enter An Address" }),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  max_distance: z.number().min(1).max(100).optional(),
});

const libraries = ["places"];

const AddressAutoComplete = ({ onSelectAddress, defaultValue }) => {
  const { isLoaded, loadError } = useGoogleMapsScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY ?? "",
    libraries,
  });

  if (!isLoaded) return null;
  if (loadError) return <div>Error loading</div>;

  return (
    <SearchBox onSelectAddress={onSelectAddress} defaultValue={defaultValue} />
  );
};

const SearchBox = ({ onSelectAddress, defaultValue }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300, defaultValue });

  const handleChange = (e) => {
    setValue(e);
    if (e === "") {
      onSelectAddress("", null, null);
    }
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      onSelectAddress(address, lat, lng);
    } catch (error) {
      toast.error(`😱 Error: ${error}`);
    }
  };

  return (
    <Command className="bg-transparent">
      <CommandInput
        id="search"
        placeholder="Address"
        value={value}
        disabled={!ready}
        className="bg-transparent border-[#3A3A3A] text-white placeholder-[#888]"
        autoComplete="grdfff"
        onValueChange={handleChange}
        onFocus={(event) => {
          event.target.setAttribute("autocomplete", "grdfff");
        }}
      />
      <CommandList
        className={cn(
          "max-h-[100px] bg-[#2A2A2A] hover:bg-[#3A3A3A] ",
          status !== "OK" && "h-0",
        )}
      >
        <CommandGroup className="!bg-transparent">
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <CommandItem
                key={place_id}
                value={description}
                onSelect={handleSelect}
                className="!bg-transparent text-white/50 "
              >
                <span>{description}</span>
              </CommandItem>
            ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export default function Component() {
  const navigate = useNavigate();

  const [availableOnly, setAvailableOnly] = useState(false);
  const [chargers, setChargers] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedCharger, setSelectedCharger] = useState(null);

  const observerTarget = useRef(null);

  const [searchChargers] = useLazySearchChargersQuery();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: "",
      searchTerm: "",
      latitude: null,
      longitude: null,
      max_distance: 1,
    },
  });

  const { data, isFetching } = useSearchChargersQuery({
    searchTerm: form.watch("searchTerm"),
    longitude: form.watch("longitude"),
    latitude: form.watch("latitude"),
    max_distance: form.watch("max_distance"),
    page,
  });

  useEffect(() => {
    if (data) {
      setChargers(data.data.results);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (data?.data.next && !availableOnly) {
            setPage((prev) => prev + 1);
            setChargers((prevChargers) => [
              ...prevChargers,
              ...data.data.results,
            ]);
          }
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [data, availableOnly]);

  function onSubmit(values) {
    setPage(1);
    searchChargers(values)
      .unwrap()
      .then((response) => {
        setChargers(response.data.results);
      });
  }

  return (
    <Wrapper className="h-full text-white">
      <div className="co">
        <div className="flex flex-col gap-4">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="p-4 bg-transparent border border-[#3A3A3A]">
              <CardContent className="space-y-4 p-0">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-2"
                  >
                    <FormField
                      control={form.control}
                      name="address"
                      render={() => (
                        <FormItem className="relative">
                          <FormControl>
                            <AddressAutoComplete
                              onSelectAddress={(
                                address,
                                latitude,
                                longitude,
                              ) => {
                                form.setValue("address", address);
                                form.setValue("latitude", latitude);
                                form.setValue("longitude", longitude);
                              }}
                              defaultValue=""
                            />
                          </FormControl>
                          <FormMessage className="text-xs text-red-500" />
                          <Button
                            disabled={!form.watch("address")}
                            size="icon"
                            className="absolute right-1 top-1 h-7 w-7 rounded-full bg-[#FED127] hover:bg-[#FED127]/90 transition-colors duration-300"
                          >
                            <ArrowUp className="h-4 w-4 text-[#1A1A1A]" />
                          </Button>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="max_distance"
                      render={({ field }) => (
                        <FormItem className="space-y-2">
                          <div className="flex justify-between items-end">
                            <FormLabel>
                              Max Distance: {field.value} km
                            </FormLabel>
                          </div>
                          <FormControl>
                            <Slider
                              min={1}
                              max={100}
                              step={1}
                              value={[field.value]}
                              onValueChange={(value) =>
                                field.onChange(value[0])
                              }
                              className="[&_[role=slider]]:bg-[#40340a] [&_[role=slider]]:border-[#40340a] [&_[role=slider]]:shadow-md"
                            />
                          </FormControl>
                          <FormMessage className="text-xs text-red-500" />
                        </FormItem>
                      )}
                    />
                  </form>
                </Form>
                {/* </Tabs> */}

                <motion.div whileTap={{ scale: 0.95 }}>
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setChargers(
                        chargers.filter(
                          (charger) => charger.charger_status === "Available",
                        ),
                      );
                      setAvailableOnly(!availableOnly);
                    }}
                    className={`text-xs border-[#3A3A3A] text-white hover:text-white transition-colors duration-300 ${
                      availableOnly
                        ? "bg-white/30 hover:bg-white/50"
                        : "bg-black hover:bg-black/50"
                    }`}
                  >
                    {availableOnly ? "Show all" : "Show available"}
                  </Button>
                </motion.div>
              </CardContent>
            </Card>
          </motion.div>
          <AnimatePresence>
            {availableOnly && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <Alert className="bg-transparent border border-[#3A3A3A]">
                  <Info className="h-4 w-4" color="#FED127" />
                  <AlertDescription>
                    Showing only available chargers
                  </AlertDescription>
                </Alert>
              </motion.div>
            )}
          </AnimatePresence>
          {isFetching && <div className="loader" />}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="overflow-y-auto max-h-[calc(100vh-350px)] space-y-4 relative no-scroll"
          >
            <AnimatePresence>
              {chargers?.length > 0 ? (
                chargers?.map((charger) => (
                  <div key={charger.charge_point_id}>
                    <Card
                      onClick={() =>
                        setSelectedCharger(
                          selectedCharger?.charge_point_id ===
                            charger.charge_point_id
                            ? null
                            : charger,
                        )
                      }
                      className="transition-colors duration-200 bg-transparent border border-[#3A3A3A] hover:border-[#FED127]"
                    >
                      <CardHeader className="flex justify-between p-2">
                        <div className="flex justify-between w-full">
                          <CardTitle className="text-sm flex flex-col">
                            <span>{charger.name}</span>
                            <span className="text-[8px] font-normal text-[#ccc]">
                              {charger.charge_point_id}
                            </span>
                          </CardTitle>

                          <Badge
                            variant={
                              charger.available ? "success" : "destructive"
                            }
                            className={cn(
                              "text-[8px] gap-1 pr-1 border-[#FED127] bg-opacity-40 text-[#1A1A1A] h-max",
                              charger.charger_status === "Unavailable" &&
                                "bg-red-500 border-red-500 text-white",
                              charger.charger_status === "Available" &&
                                "bg-green-500 border-green-500 text-white",
                            )}
                          >
                            {charger.charger_status}
                            <ChevronDown
                              className={cn(
                                "h-3 w-3 transition-transform duration-300",
                                selectedCharger?.charge_point_id ===
                                  charger.charge_point_id && "rotate-180",
                              )}
                            />
                          </Badge>
                        </div>
                      </CardHeader>
                      <CardContent className="p-2 pt-0">
                        <div className="flex items-center justify-between">
                          <span className="text-[10px] truncate text-[#888]">
                            {charger.address}
                          </span>

                          <Button
                            size="sm"
                            disabled={charger.charger_status !== "Available"}
                            className="text-[10px] bg-[#FED127] hover:bg-[#FED127]/50 text-[#1A1A1A] h-auto py-1 px-2"
                            onClick={() => {
                              navigate(`/?charger=${charger.charge_point_id}`);
                            }}
                          >
                            <BatteryCharging className="h-4 w-4 mr-1 " />
                            Charge
                          </Button>
                        </div>
                        {charger?.distance ? (
                          <p className="text-[8px] text-[#888] text-right">
                            {charger.distance} km
                          </p>
                        ) : null}

                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{
                            height:
                              selectedCharger?.charge_point_id ===
                              charger.charge_point_id
                                ? "auto"
                                : 0,
                            opacity:
                              selectedCharger?.charge_point_id ===
                              charger.charge_point_id
                                ? 1
                                : 0,
                          }}
                          transition={{ duration: 0.3 }}
                          className="overflow-hidden"
                        >
                          <div className="flex justify-between items-center pt-2">
                            <div className="flex items-center">
                              <Plug className="h-4 w-4 mr-1 text-[#FED127]" />
                              <span className="text-[8px] font-medium text-white">
                                {charger.connectors.map((connector) => (
                                  <span key={connector.id} className="ml-1">
                                    {connector.connector_id} (
                                    {connector.connector_status})
                                  </span>
                                ))}
                              </span>
                            </div>
                            <div className="flex items-center">
                              <span className="text-[8px] font-medium text-white">
                                Latitude: {charger.latitude}, Longitude:{" "}
                                {charger.longitude}
                              </span>
                            </div>
                          </div>
                        </motion.div>
                      </CardContent>
                    </Card>
                  </div>
                ))
              ) : (
                <div>
                  <Card className="bg-transparent border border-[#3A3A3A]">
                    <CardContent className="p-6 text-center">
                      <p className="text-[#888]">
                        No chargers found matching your criteria.
                      </p>
                    </CardContent>
                  </Card>
                </div>
              )}
            </AnimatePresence>

            <div ref={observerTarget} />
          </motion.div>
        </div>
      </div>
    </Wrapper>
  );
}
