import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactionId: null,
  timer: 0,
  elapsedTime: 0,
  meterValues: {
    charging_rate: null,
    duration: null,
    energy_consumed: null,
    total_price: null,
  },
};

const sessionSlice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    setMeterValues: (state, action) => {
      state.meterValues = action.payload;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    resetMeterValues: (state, _action) => {
      state.meterValues = initialState.meterValues;
      state.elapsedTime = 0;
    },
    setTimer: (state, action) => {
      state.timer = action.payload;
    },
    setElapsedTime: (state, action) => {
      state.elapsedTime = action.payload;
    },
  },
});

export const {
  setTimer,
  setMeterValues,
  setTransactionId,
  resetMeterValues,
  setElapsedTime,
} = sessionSlice.actions;
export default sessionSlice.reducer;
