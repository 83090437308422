import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LightButton } from "@/components/ui/light-button";
import app from "@/lib/config/firebase.config";
import { useDeleteUserProfileMutation } from "@/redux/reducers/auth/auth.api";
import { setAppLoading, setUser } from "@/redux/reducers/auth/auth.slice";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAuth, signOut } from "firebase/auth";
import { ArrowRight, Trash2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  confirm_delete: z
    .string({ required_error: "Please confrim delete" })
    .refine((value) => value === "Confirm-Delete", {
      message: 'Please enter "Confirm-Delete" to proceed',
    }),
});

export const DeleteAccount = () => {
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const auth = getAuth(app);
  const dispatch = useDispatch();
  const [deleteAccount, { isLoading }] = useDeleteUserProfileMutation();

  const [isOpen, setIsOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("delete-account") === "true") {
      setIsOpen(true);
    }
  }, [searchParams]);

  const handleSubmit = async () => {
    dispatch(setAppLoading(true));
    deleteAccount()
      .unwrap()
      .then(() => {
        signOut(auth)
          .then(() => {
            dispatch({ type: "USER_LOGOUT" });
            dispatch(setUser(null));
            sessionStorage.clear();
            toast.info(
              "Account has been deleted successfully. Log in within 30 days to restore your account.",
            );
          })
          .catch((error) => console.error(error))
          .finally(() => dispatch(setAppLoading(false)));
      })
      .catch((error) => {
        toast.error(error.data.eror_message);
      })
      .finally(() => dispatch(setAppLoading(false)));
  };

  return (
    <Dialog modal open={isOpen}>
      <DialogTrigger
        onClick={() => {
          setIsOpen(true);
          form.reset();
          setSearchParams({ "delete-account": "true" });
        }}
        asChild
      >
        <LightButton
          icon={Trash2Icon}
          className="w-full justify-between !p-3 h-auto rounded-2xl"
        >
          Request account deletion
        </LightButton>
      </DialogTrigger>
      <DialogContent
        onClose={() => {
          setIsOpen(false);
          form.reset();
          setSearchParams({});
        }}
        className=" max-w-xs space-y-6 items-center border-none rounded-[30px] backdrop-blur-[10px] bg-[#CECECE2E]"
      >
        <DialogHeader>
          <DialogTitle className="text-[#f00] text-[28px] font-bold">
            Danger!
          </DialogTitle>
          <DialogDescription className="text-white">
            This is a irreversible action, once you delete your account it
            cannot be recovered
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6 flex-col flex items-center">
          <img src="/images/danger.png" alt="danger" />

          <div>
            Please enter{" "}
            <span className="text-[#f00] font-bold ">Confirm-Delete</span>
          </div>
          <hr className="w-full" />
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="flex gap-[2px]"
            >
              <FormField
                name="confirm_delete"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="relative rounded-l-lg !rounded-r-none  flex-[5] h-full">
                    <FormControl>
                      <Input
                        style={{
                          boxShadow: "0px 0px 20px 0px #F00 inset",
                        }}
                        className="border-0 bg-black text-[#f00] font-bold"
                        placeholder="Confirm-Delete"
                        {...field}
                      />
                    </FormControl>

                    <FormMessage className=" text-[10px] absolute" />
                  </FormItem>
                )}
              />
              <div
                className="  rounded-r-lg flex-[1] "
                style={{
                  background: "rgba(0, 0, 0, 0.65);",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
              >
                <Button
                  disabled={isLoading}
                  type="submit"
                  id="sign-in-button"
                  className=" bg-black w-full h-full border-0  rounded-r-lg rounded-l-none"
                  style={{
                    boxShadow: "  0px 0px 20px 0px #f00 inset",
                  }}
                >
                  <ArrowRight color="#f00" size={20} />
                </Button>
              </div>

              <div id="recaptcha" />
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
