import { cn } from "@/lib/utils/cn";
import { useLazyGetChargersWithinDistanceQuery } from "@/redux/reducers/charger/charger.api";
import { setChargersWithinDistanceData } from "@/redux/reducers/charger/charger.slice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { buttonVariants } from "../ui/button";
import { ChargerBoxesHeader } from "./charger-boxes-header";

export const ChargerBoxBusy = () => {
  const dispatch = useDispatch();
  const distance = 20;

  const [trigger, { isLoading, isError, isSuccess }] =
    useLazyGetChargersWithinDistanceQuery();
  const { chargePointInfo, chargersWithinDistanceData } = useSelector(
    (state) => state.charger
  );

  // Function to fetch chargers within distance
  const fetchChargersWithinDistance = useCallback(async () => {
    try {
      const { data } = await trigger({
        latitude: chargePointInfo.charge_point_latitude,
        longitude: chargePointInfo.charge_point_longitude,
        distance: distance,
      });
      dispatch(setChargersWithinDistanceData(data?.data));
    } catch (error) {
      console.error("Error fetching chargers:", error);
    }
  }, [trigger, chargePointInfo, dispatch]);

  // Trigger fetch when chargePointInfo changes
  useEffect(() => {
    if (chargePointInfo) {
      fetchChargersWithinDistance();
    }
  }, [chargePointInfo, fetchChargersWithinDistance]);

  // Helper function to render charger count link
  const renderChargerCount = () => {
    const count = chargersWithinDistanceData?.length || 0;

    if (count === 0) return "0";
    if (count > 10) return "10+";
    return count;
  };

  // Conditional content rendering
  const renderContent = () => {
    if (isLoading) return <>Loading</>;
    if (isError) return <>Error</>;
    if (isSuccess && chargersWithinDistanceData) {
      return (
        <div>
          <p>There are currently</p>
          <div
            style={{ backgroundImage: "url(/images/ellipse.png)" }}
            className="bg-center bg-no-repeat bg-cover flex items-center justify-center"
          >
            <Link
              to="/available-chargers"
              className={cn(
                buttonVariants({ variant: "link" }),
                "h-auto text-[89px] text-[#7EBC78]"
              )}
            >
              {renderChargerCount()}
            </Link>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 rounded-2xl bg-[#CECECE30] flex flex-col items-center gap-3 w-full">
      <ChargerBoxesHeader status="busy" />
      {renderContent()}
      <div className="space-y-3">
        <p className="text-xs text-center font-thin max-w-[150px] mx-auto">
          available chargers within {distance}km radius of here
        </p>
      </div>
    </div>
  );
};
