import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import app from "../config/firebase.config";

const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: "https://core.grdflo.com/",
  prepareHeaders: (headers) => {
    const token = sessionStorage.getItem("access::token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const auth = getAuth(app);

      try {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const idToken = await getIdToken(auth.currentUser);
            sessionStorage.setItem("access::token", idToken);
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch({ type: "USER_LOGOUT" });
          }
        });
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const baseQueryWithoutAuth = fetchBaseQuery({
  baseUrl: "https://core.grdflo.com/",
  prepareHeaders: (headers) => {
    return headers;
  },
});
