import { formatTime } from "@/lib/utils/date";
import { useSelector } from "react-redux";

const ChargingSession = () => {
  const { meterValues, elapsedTime } = useSelector((state) => state.session);
  const { currency } = useSelector((state) => state.wallet);

  return (
    <div className=" p-4  rounded-[30px] bg-[#CECECE30] w-full grid grid-cols-2 gap-4 items-start">
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Charging Rate</span>
        <div className=" text-2xl font-bold">
          {meterValues.charging_rate || 0} kW
        </div>
      </div>
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Time Elapsed</span>
        <div className=" text-2xl font-bold">{formatTime(elapsedTime)}</div>
      </div>{" "}
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Energy Consumed</span>
        <div className=" text-2xl font-bold">
          {meterValues.energy_consumed || 0} kWh
        </div>
      </div>{" "}
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Cost of Charging</span>
        <div className=" text-2xl font-bold">
          {currency} {meterValues.total_price || 0}
        </div>
      </div>
    </div>
  );
};

export default ChargingSession;
