import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD5vYy1D1gOW7chwv56wDMMaS04oRWn610",
  authDomain: "grdflow-drive-25.firebaseapp.com",
  projectId: "grdflow-drive-25",
  storageBucket: "grdflow-drive-25.firebasestorage.app",
  messagingSenderId: "956697325383",
  appId: "1:956697325383:web:d5b24cdff03b54884f2662",
  measurementId: "G-P5TF7EGZLB",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
