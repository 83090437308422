import { useState } from "react"
import { Check, ChevronsUpDown, Loader2 } from "lucide-react"
import { Button } from "../ui/button"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Label } from "@/components/ui/label"
import { useSelector } from "react-redux"
import { cn } from "@/lib/utils/cn"

export default function ConnectorDropdown({connectors, onSelectConnector, selectedConnector}) {
    const [open, setOpen] = useState(false)

  // const { chargePointInfo } = useSelector((state) => state.charger);

  return (
    <div className="space-y-2 w-full">
        <Popover className = "bg-black" open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              id="connector-select"
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="w-full justify-between bg-black text-white border-gray-700 hover:bg-gray-900 hover:text-white"
            >
              {selectedConnector ? `Connector ${selectedConnector}` : "Select a connector..."}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full p-0 bg-black border-gray-700">
            <Command>
              <CommandInput placeholder="Search connectors..." />
              <CommandList className ="bg-black text-white">
                <CommandEmpty>No connectors found.</CommandEmpty>
                <CommandGroup>
                  {connectors.length > 0 ? (
                    connectors.map((connector) => {
                      const isAvailable = connector.connector_status === "Available"
                      return (
                        <CommandItem
                          key={connector.connector_id}
                          value={`connector-${connector.connector_id}`}
                          onSelect={() => isAvailable && onSelectConnector(connector)}
                          disabled={!isAvailable}
                          className={cn("hover:text-blue-500 group",!isAvailable && "opacity-60 cursor-not-allowed")}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4 group-hover:text-black",
                              selectedConnector === connector.connector_id ? "opacity-100" : "opacity-0",
                            )}
                          />
                          <span className="flex-1 group-hover:text-black">Connector {connector.connector_id}</span>
                          <span
                            className={cn(
                              "ml-2 text-xs px-2 py-0.5 rounded-full",
                              isAvailable ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800",
                            )}
                          >
                            {connector.connector_status}
                          </span>
                        </CommandItem>
                      )
                    })
                  ) : (
                    <CommandItem disabled>No connectors available</CommandItem>
                  )}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
  )



}