import { Button, buttonVariants } from "@/components/ui/button";
import { Wrapper } from "@/components/wrapper";
import { cn } from "@/lib/utils/cn";
import { motion } from "framer-motion";
import { Profile } from "iconsax-react";
import { MapIcon } from "lucide-react";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <Wrapper>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center justify-center px-4"
      >
        <div className="flex items-center flex-col text-center">
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 2,
              repeat: Number.Infinity,
              repeatType: "reverse",
            }}
          >
            <img src="/images/broken.png" alt="" height={130} width={130} />
          </motion.div>
          <motion.h1
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-4xl font-bold text-gray-100 mb-4"
          >
            Oops! Page not found :(
          </motion.h1>
          <motion.p
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="text-xl text-gray-200 mb-8"
          >
            It seems we&apos;ve run out of power on this page. Let&apos;s get
            you back on the road!
          </motion.p>
          <motion.div
            className="flex flex-col sm:flex-row justify-center gap-4"
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.2,
                },
              },
            }}
            initial="hidden"
            animate="show"
          >
            {[
              { to: "/", icon: Profile, text: "Account" },
              { to: "/map", icon: MapIcon, text: "Find Stations" },
            ].map((button, index) => (
              <motion.div
                key={button.to}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  show: { opacity: 1, y: 0 },
                }}
              >
                <Button
                  asChild
                  variant={index === 0 ? "default" : "outline"}
                  // className="flex items-center w-full sm:w-auto"
                  style={{
                    borderRadius: "15px",
                    background:
                      "linear-gradient(120deg, rgba(254, 209, 39, 0.00) 7.79%, #FFCA00 208.75%), #cecece2d",

                    backdropFilter: "blur(10px)",
                  }}
                  className={cn(
                    buttonVariants(),
                    "rounded-2xl relative p-3 overflow-hidden w-full",
                  )}
                >
                  <Link to={button.to}>
                    <button.icon className="mr-2 h-4 w-4" />
                    {button.text}
                  </Link>
                </Button>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.div>
    </Wrapper>
  );
}
