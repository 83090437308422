export const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const formatDateString = (dateString) => {
  // Parse the input date string
  const date = new Date(dateString);

  // Format the date using Intl.DateTimeFormat
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  return formattedDate;
};
