import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useRemoteStartTransactionMutation,
  useRemoteStopTransactionMutation,
} from "@/redux/reducers/charger/charger.api";
import {
  setElapsedTime,
  setMeterValues,
} from "@/redux/reducers/session/session.slice";
import { useGetUserProfileQuery } from "@/redux/reducers/auth/auth.api";
import { Client } from "@stomp/stompjs";
import { toast } from "sonner";
import { accurateTimer } from "../utils/timer";
import { useNavigate } from "react-router-dom";

const EXCHANGE_NAME = import.meta.env.VITE_REMOTE_EXCHANGE_NAME;
const START_TRANSACTION_ROUTING_KEY = import.meta.env
  .VITE_START_TRANSACTION_ROUTING_KEY;
const STOP_TRANSACTION_ROUTING_KEY = import.meta.env
  .VITE_STOP_TRANSACTION_ROUTING_KEY;
const TRANSACTION_ID_ROUTING_KEY = import.meta.env
  .VITE_TRANSACTION_ID_ROUTING_KEY;
const TRANSACTION_EXCHANGE_NAME = import.meta.env
  .VITE_TRANSACTION_EXCHANGE_NAME;

// const RABBITMQ_URL =
//   import.meta.env.VITE_ENV === "production"
//     ? `wss://${import.meta.env.VITE_RABBITMQ_HOST}:15674/ws`
//     : `ws://${import.meta.env.VITE_RABBITMQ_HOST}:15674/ws`;

const RABBITMQ_URL = `wss://${import.meta.env.VITE_RABBITMQ_HOST}:15674/ws`;    

const RABBITMQ_USER = import.meta.env.VITE_RABBITMQ_USER;
const RABBITMQ_PASSWORD = import.meta.env.VITE_RABBITMQ_PASSWORD;
const RABBITMQ_VHOST = import.meta.env.VITE_RABBITMQ_VHOST;
const RABBITMQ_VHOST_DRIVE = import.meta.env.VITE_RABBITMQ_VHOST_DRIVE;

console.log("RABBITMQ_URL: ", RABBITMQ_URL);
console.log("Environment: ", import.meta.env.VITE_ENV);

export const useCharging = () => {
  const [isCharging, setIsCharging] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [requestStatusClient, setRequestStatusClient] = useState(null); // gets the status of the request (START / STOP)
  const [getTransactionIdClient, setGetTransactionIdClient] = useState(null); // gets the transaction id of the session

  const [isReadyToCharge, setIsReadyToCharge] = useState(false);

  const [startChargingInProgress, setStartChargingInProgress] = useState(false);
  const [stopChargingInProgress, setStopChargingInProgress] = useState(false);

  const [timer, setTimer] = useState(null);

  const [meterValuesClient, setMeterValuesClient] = useState(null); // gets the transaction id of the session
  const { data: userData, isLoading: userLoading } = useGetUserProfileQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const client = new Client({
      brokerURL: RABBITMQ_URL,
      connectHeaders: {
        login: RABBITMQ_USER,
        passcode: RABBITMQ_PASSWORD,
        host: RABBITMQ_VHOST,
      },
      onConnect: () => {
        setRequestStatusClient(client);
      },
      onStompError: (frame) => {
        console.log("Broker reported error: " + frame.headers["message"]);
        console.log("Additional details: " + frame.body);
      },
      onWebSocketError: (event) => {
        console.log("Broker reported WebSocket error: " + event);
      },
    });

    client.activate();

    return () => {
      client.deactivate();
    };
  }, []);

  useEffect(() => {
    const client = new Client({
      brokerURL: RABBITMQ_URL,
      connectHeaders: {
        login: RABBITMQ_USER,
        passcode: RABBITMQ_PASSWORD,
        host: RABBITMQ_VHOST_DRIVE,
      },
      onConnect: () => {
        setGetTransactionIdClient(client);
      },
      onStompError: (frame) => {
        console.log("Broker reported error: " + frame.headers["message"]);
        console.log("Additional details: " + frame.body);
      },
      onWebSocketError: (event) => {
        console.log("Broker reported WebSocket error: " + event);
      },
    });

    client.activate();

    return () => {
      client.deactivate();
    };
  }, []);

  useEffect(() => {
    const client = new Client({
      brokerURL: RABBITMQ_URL,
      connectHeaders: {
        login: RABBITMQ_USER,
        passcode: RABBITMQ_PASSWORD,
        host: RABBITMQ_VHOST_DRIVE, //grdflo_drive_host
      },
      onConnect: () => {
        setMeterValuesClient(client);
      },
      onStompError: (frame) => {
        console.log("Broker reported error: " + frame.headers["message"]);
        console.log("Additional details: " + frame.body);
      },
      onWebSocketError: (event) => {
        console.log("Broker reported WebSocket error: " + event);
      },
    });

    client.activate();

    return () => {
      client.deactivate();
    };
  }, []);

  // starts the timer when transaction id is set
  useEffect(() => {
    if (!transactionId) return;

    const startTime = new Date().getTime();

    const timer = accurateTimer(() => {
      const elapsedTime = new Date().getTime() - startTime;
      dispatch(setElapsedTime(Math.round(elapsedTime / 1000)));
    });

    setTimer(timer);
  }, [transactionId]);

  // listens for real time meter values
  useEffect(() => {
    if (!transactionId || !meterValuesClient) return;

    const meterValuesHeader = {
      "x-bindings": JSON.stringify([
        {
          exchange: TRANSACTION_EXCHANGE_NAME, // gridflow_drive_exchange
          routing_key: `charge_session.${chargePointInfo.charge_point_id}.${transactionId}`,
        },
      ]),
    };

    console.log("headers: ", meterValuesHeader);

    meterValuesClient.subscribe(
      `/exchange/gridflow_drive_exchange/charge_session.${chargePointInfo.charge_point_id}.${transactionId}`, //EXCHANGE_NAME = ocpp_server_exchange
      (message) => {
        const parsedMessage = JSON.parse(message.body);
        dispatch(
          setMeterValues({
            ...parsedMessage,
            duration: 0,
          })
        );
        console.log("meter values parsedMessage: ", parsedMessage);
        message.ack();
      },
      {
        // ...meterValuesHeader,
        ack: "client",
      }
    );
  }, [meterValuesClient, transactionId]);

  // subscribe to remote_stop_transaction_response

  useEffect(() => {
    if (!requestStatusClient) return;

    requestStatusClient.subscribe(
      `/exchange/${EXCHANGE_NAME}/${STOP_TRANSACTION_ROUTING_KEY}`,
      (message) => {
        const parsedMessage = JSON.parse(message.body);
        if (parsedMessage.status === "Accepted") {
          setIsCharging(false);
          setStopChargingInProgress(false);
          toast.success("Charging finished successfully");

          setTimeout(() => {
            navigate("/complete-charge");
          }, 1000);
          timer.cancel();
        }
      }
    );
  }, [requestStatusClient, timer]);

  const { chargePointInfo } = useSelector((state) => state.charger);

  const [_startCharging, { isLoading: _startChargingLoading }] =
    useRemoteStartTransactionMutation();

  const [_stopCharging, { isLoading: stopChargingLoading }] =
    useRemoteStopTransactionMutation();

  useEffect(() => {
    if (requestStatusClient && getTransactionIdClient) {
      setIsReadyToCharge(true);
    } else {
      setIsReadyToCharge(false);
    }
  }, [requestStatusClient, getTransactionIdClient]);

  const startCharging = async (connectorId) => {
    if (!connectorId) {
      toast.warning("Please select a Connector");
      return;
    }

    setStartChargingInProgress(true);
    try {
      const response = await _startCharging({
        charge_point_id: chargePointInfo.charge_point_id,
        connector_id: connectorId,
        id_tag: userData.data.id_tag,
      }).unwrap();
      console.log("response: ", response);
    } catch (error) {
      setStartChargingInProgress(false);
      console.error(error);
    }

    if (requestStatusClient) {
      requestStatusClient.subscribe(
        `/exchange/${EXCHANGE_NAME}/${START_TRANSACTION_ROUTING_KEY}`,
        (message) => {
          const parsedMessage = JSON.parse(message.body);
          console.log("request status parsedMessage: ", parsedMessage);
        }
      );
    }

    if (getTransactionIdClient) {
      getTransactionIdClient.subscribe(
        `/exchange/${TRANSACTION_EXCHANGE_NAME}/${TRANSACTION_ID_ROUTING_KEY}`,
        (message) => {
          const parsedMessage = JSON.parse(message.body);
          console.log("transaction id parsedMessage: ", parsedMessage);
          if (parsedMessage.transaction_id) {
            setTransactionId(parsedMessage.transaction_id);
            setIsCharging(true);
            setStartChargingInProgress(false);
          }
        }
      );
    }
  };

  const stopCharging = async () => {
    setStopChargingInProgress(true);
    try {
      const response = await _stopCharging({
        charge_point_id: chargePointInfo.charge_point_id,
        transaction_id: transactionId,
      }).unwrap();
    } catch (error) {
      setStopChargingInProgress(false);
      console.error(error);
    }
    // if (requestStatusClient) {
    //   requestStatusClient.subscribe(
    //     `/exchange/${EXCHANGE_NAME}/${STOP_TRANSACTION_ROUTING_KEY}`,
    //     (message) => {
    //       const parsedMessage = JSON.parse(message.body);
    //       if (parsedMessage.status === "Accepted") {
    //         setIsCharging(false);
    //         setStopChargingInProgress(false);
    //         toast.success("Charging finished successfully");

    //         setTimeout(() => {
    //           navigate("/complete-charge");
    //         }, 1000);
    //         timer.cancel();
    //       }
    //     }
    //   );
    // }
  };

  return {
    startCharging,
    stopCharging,
    isCharging,
    transactionId,
    chargePointInfo,
    isReadyToCharge,
    startChargingInProgress,
    stopChargingInProgress,
  };
};

